//Chakra UI
import { Box, Grid, GridItem, Image, Stack, useBreakpointValue } from '@chakra-ui/react'
// React
import React, { useEffect, useRef, useState } from "react"

import { Helmet } from "react-helmet"
import ImageOverlayOne from '../components/imageOverlayOne'
import ImageTag from '../components/imageTag'
// Components
import ImageWithOverlay from '../components/imageOverlay'
import Layout from "../layout"
import PageTransition from 'gatsby-plugin-page-transitions';
import Svg from '../images/statys-background-animation-large.svg'
import { graphql } from 'gatsby'
import parse from "html-react-parser"

const Index = ({ data, req, location }) => {
  
  // useEffect(() => {
  //   navigate('/coming-soon');
  // }, []);
  // return null;
    // console.log(req.geo.city)
  
  const isMobile = useBreakpointValue({ base: true, xl: false })

  // array of all articles
  const posts = data.allPosts.nodes

  const stickyP = data.stickyPost.nodes 

  //Infitnity scroll code 

  // state of the list 
  const [list, setList] = useState([...posts.slice(0, 5)])

  // state of load more
  const [loadMore, setLoadMore] = useState(false)

  // are there any more posts to load?
  const [hasMore, setHasMore] = useState(posts.length > 5)

  // !idk what is this
  const loadRef = useRef()

  // Handle intersection with load more div
  const handleObserver = (entities) => {
    const target = entities[0]
    if (target.isIntersecting) {
      setLoadMore(true)
    }
  }

    //Initialize the intersection observer API
  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [])

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < posts.length
      const nextResults = isMore
        ? posts.slice(currentLength, currentLength + 5)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < posts.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Aesthetics Market Research | Get the Latest Industry Insights Today</title>
          <meta name="description" content="This market research focuses on the aesthetics industry, examining current trends and collecting data on the attitudes, behaviors, and preferences of consumers" />
          <meta name="keywords" content="aesthetic industry trends, medical aesthetics industry growth, global aesthetics market, aesthetic industry growth, medical" />
        </Helmet>
      <PageTransition>
      {!isMobile && (<Image alt="Background animation" src={Svg} style={{ position: `absolute`, top: `0`, left: `0` }} ></Image>)}

      {/* FEATURED POST */}
      {/* <Grid maxW="container.xl" height={{ md: '100%' }} pt="140px" pb="48px" mx="auto" px={{ base: '4', md: '8', lg: '12', xl: "0"}}>
        {stickyP.map((one) =>
          <ImageOverlayOne key={one.id} one={one} maxW="container.xl" height="720px" content={parse(one.excerpt)} ></ImageOverlayOne>
        )}
      </Grid> */}

      {/* BLOCK */}
      <Box pt="140px" pb="24px" px={{ base: '4', md: '8', lg: '12' }} >
        <Stack maxW="container.xl" mx="auto" direction={{ base: 'column', lg: 'row' }} spacing={{ base: '6', md: '10' }} align="stretch" >
            <Box width="100%">
            {stickyP.map((one) =>
           
                <ImageOverlayOne key={one.id} one={one} content={parse(one.excerpt)} ></ImageOverlayOne>
        
            )}
            </Box>
          
         
          <Stack spacing={{ base: '6', md: '10' }} maxW={{base:'full', lg: '400px' }} justifyContent="space-around">
            {posts.slice(0,3).map((tag) =>
              <ImageTag key={tag.id} item={tag}/>
            )}
          </Stack>
        </Stack>
      </Box>

      {/* ALL POSTS */}
      <Box>
      <Grid maxW="container.xl" mx="auto" px={{ base: '4', md: '8', lg: '12', xl: "0" }} pb="80px" className="test" templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)'}} gap={6}>
        {posts.slice(4,).map(post =>
          <GridItem >
            <ImageWithOverlay key={post.id} item={post} />
          </GridItem>
          )}
        <div ref={loadRef}>
          {hasMore ? <p>Loading...</p> :  <p> </p>}
        </div>
        </Grid>
        </Box>
        </PageTransition> 
    </Layout>
  )
}

export default Index

//GRAPHQL
export const pageQuery = graphql`
  {
    allPosts: allWpPost(filter: {isSticky: {eq: false}}, sort: {order: DESC, fields: date}) {
      nodes {
        id
        link
        slug
        excerpt
        uri
        content
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        categories {
          nodes {
            name
            slug
          }
        }
        topics {
          nodes {
            name
          }
        }
        author {
          node {
            name
          }
        }
         featuredImage {
          node {
            link
            srcSet
            sourceUrl
         }
        }
      }
    }
  stickyPost: allWpPost(filter: {isSticky: {eq: true}}, limit: 1) {
      nodes {
        link
        slug
        isSticky
        title
        uri
        date(formatString: "MMMM DD, YYYY")
        excerpt
        content
        categories {
          nodes {
            name
            slug
          }
        }
        topics {
          nodes {
            name
          }
        }
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
    tagPosts: allWpPost(
      limit: 2
      filter: {tags: {nodes: {elemMatch: {slug: {eq: "resources"}}}}}
    ) {
      nodes {
        link
        slug
        isSticky
        title
        uri
        date(formatString: "MMMM DD, YYYY")
        excerpt
        content
        categories {
          nodes {
            name
            slug
          }
        }
        topics {
          nodes {
            name
          }
        }
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
     onePost: allWpPost(limit: 1) {
      nodes {
        id
        link
        slug
        excerpt
        uri
        content
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        categories {
          nodes {
            name
            slug
          }
        }
        topics {
          nodes {
            name
          }
        }
        author {
          node {
            name
          }
        }
         featuredImage {
          node {
            link
            srcSet
            sourceUrl
         }
        }
      }
    }
  }
`