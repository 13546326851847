import * as React from 'react'

//Chakra UI
import { Box, Button, Heading, Stack, Tag } from '@chakra-ui/react'

//Components
import { IconArrowUpRight } from '@tabler/icons'
// React
import { Link } from 'gatsby'

const ImageTag = ({ item }) => {
    
  const categories = item.categories.nodes
  return (
      <Box className='testChild' height={{ base: '380px', md: '200px'}} bg={"linear-gradient(180deg, rgba(11, 17, 40, 0) 0%, #0B1128 97.4%), url("+ item.featuredImage.node.sourceUrl + ")"} px={{base: '6', md: '8', }} py={{ base: '6', md: '8', }} backgroundSize="cover" position="relative" borderRadius="xl">
        <Stack spacing="4" height="100%" >
          <Stack spacing="4" height="inherit" justifyContent="end">
            {/* s<Tag bg="blue.500" color="base.50" maxW="max-content" fontWeight="700" fontSize="xs" >RESOURCES</Tag> */}
            <Heading fontSize="22px !important" mt="8px !important" mb="0px !important" filter="drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));" color="white">
              {item.title}
            </Heading>
            
              {categories.map((cat) => {
                if (cat.slug === "news") {
                  return <Link href={"/news" + item.uri} rel="canonical" aria-label={item.title}>
                    <Button variant="ghost2" rightIcon={<IconArrowUpRight size="12px" />}> Read Article</Button>
                  </Link>
                } else if (cat.slug === "insights") {
                  return <Link href={"/insights" + item.uri} rel="canonical" aria-label={item.title} >
                    <Button variant="ghost2" rightIcon={<IconArrowUpRight size="12px" />}> Read Article</Button>
                  </Link>
                }
          } 
          )}
          </Stack>
        </Stack>
      </Box>
  )
}

export default ImageTag