import * as React from 'react'

//Chakra UI
import { Box, Button, HStack, Heading, Stack, Text } from '@chakra-ui/react'
import { areThereAnyTopics, getWordCount } from "../components/helpers"

//Components
import {Dots} from './dots'
import { IconArrowUpRight } from '@tabler/icons'
// React
import { Link } from 'gatsby'

const ImageOverlayOne = ({ one, content }) => {

  // const topics = one.topics.nodes
  const categories = one.categories.nodes

  return (
    <Box bg={"linear-gradient(180deg, rgba(11, 17, 40, 0) 0%, #0B1128 94.27%), url("+ one.featuredImage.node.sourceUrl + ")"} backgroundBlendMode="multiply, normal" backgroundPosition="center" px={{base: '6', md: '10', }} py={{ base: '6', md: '10', }} backgroundSize="cover" position="relative" borderRadius="xl">
        {/* <Stack spacing="4" height="720px" > */}
          <Stack spacing="4" maxWidth="container.sm" height="container.sm" justifyContent="end">
          
            {/* {areThereAnyTopics(topics)} */}
           
            {/* <HStack mt="0px !important" mb="0px !important">
              {one.topics.nodes.map((topic, index, array) => {
                const lastElement = array.length - 1;
                console.log(topic)
        
                if (lastElement === index) {
                  return <Text color="base.50" m='none' mb="0px !important" fontSize="xs" fontWeight="bold"> {topic.name}</Text>
                } else {
                 return <>
                    <Text color="base.50" m='none' mb="0px !important" fontSize="xs" fontWeight="bold"> {topic.name}</Text>
                    <Dots></Dots>
                  </>
                }
              })}
            </HStack> */}
           {/* <Heading fontSize="48px !important" m="0px !important" color="white" filter="drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));" >{one.title} </Heading>  */}
               {categories.map((cat) => {
                {/* if (cat.slug === "news") {
                return <Link href={"/news" + one.uri} style={{marginTop: "0px !important"}}>
                    <Heading fontSize={{base: "30px !important", md: "48px !important"}} m="0px !important" color="white" filter="drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));" >{one.title} </Heading>
                    </Link>
                } else */}
                  if (cat.slug === "insights") {
                    return <Link href={"/insights" + one.uri} rel="canonical" aria-label={one.title} style={{marginTop: "0px !important"}}> 
                      <Heading fontSize={{base: "30px !important", md: "48px !important"}} m="0px !important" color="white" filter="drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));" >{one.title} </Heading>
                    </Link>
                }
              } 
            )} 
        
            <Stack direction="row" align="center">
                {/* <Text color="base.50" fontWeight="700" fontSize="12px" > {one.author.node.name} </Text>
                <Dots></Dots>  */}
                <Text color="base.50" mb="0px !important" fontWeight="700" fontSize="12px" > {one.date}  </Text>
                <Dots></Dots>
                <Text color="base.50" fontWeight="700" fontSize="12px" > {getWordCount(one.content) + " min read"}  </Text>
            </Stack> 
                  
            {content && (<Text color="base.50" className='text-white'>{content}</Text>)}
                  
            {categories.map((cat) => {
                if (cat.slug === "news") {
                return <Link href={"/news" + one.uri} rel="canonical" aria-label={one.title} width="max-content" >
                  <Button variant="ghost2" rightIcon={<IconArrowUpRight size="12px"/>}> Read Article</Button>
                  </Link>
                } else if (cat.slug === "insights") {
                  return <Link href={"/insights" + one.uri} rel="canonical" aria-label={one.title}>
                  <Button variant="ghost2" rightIcon={<IconArrowUpRight size="12px"/>}> Read Article</Button>
                  </Link>
                }
              } 
            )}
          </Stack>
        {/* </Stack> */}
      </Box>
  )
}

export default ImageOverlayOne