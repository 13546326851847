import * as React from 'react'

//Chakra UI
import { Box, Button, Heading, Stack, Text } from '@chakra-ui/react'

//Components
import {Dots} from './dots'
import { IconArrowUpRight } from '@tabler/icons'
// React
import { Link } from 'gatsby'
import { getWordCount } from "../components/helpers"

const ImageWithOverlay = ({ item, content }) => {

  const categories = item.categories.nodes

  return (
    <Box height={{ base: '380px', md: '460px'}} bg={"linear-gradient(180deg, rgba(11, 17, 40, 0) 0%, #0B1128 94.27%), url("+ item.featuredImage.node.sourceUrl + ")"} backgroundBlendMode="multiply, normal" px={{base: '6', md: '10', }} py={{ base: '6', md: '10', }} backgroundSize="cover" position="relative" borderRadius="xl">
        <Stack spacing="4" height={{ base: '100%', md: '100%'}} >
        <Stack spacing="4" height={{ base: '100%', md: '100%' }} justifyContent="end">
          
             {categories.map((cat) => {
            if (cat.slug === "news") {
              return <Link href={"/news" + item.uri} aria-label={item.title} rel="canonical" width="max-content" >
                  <Heading fontSize="24px !important" m="0px !important" color="white" filter="drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));"  >{item.title}</Heading>
                  </Link>
            } else if (cat.slug === "insights") {
                return <Link href={"/insights" + item.uri} aria-label={item.title} rel="canonical" >
                  <Heading fontSize="24px !important" m="0px !important" color="white" filter="drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));"  >{item.title}</Heading>
                  </Link>
            }
          } 
          )}
          
          {content && (<Text color="base.50" className='text-white'>{ content }</Text>)}
        
            <Stack direction="row" align="center">
                {/* <Text color="base.50" fontWeight="700" fontSize="12px" > {item.author.node.name} </Text>
                <Dots></Dots> */}
                <Text color="base.50" mb="0px !important" fontWeight="700" fontSize="12px" > {item.date}  </Text>
                <Dots></Dots>
                <Text color="base.50" fontWeight="700" fontSize="12px" > {getWordCount(item.content) + " min read"}  </Text>
            </Stack> 
            
          {categories.map((cat) => {
            if (cat.slug === "news") {
              return <Link href={"/news" + item.uri} aria-label={item.title} rel="canonical" width="max-content" >
                  <Button variant="ghost2" rightIcon={<IconArrowUpRight size="12px"/>}> Read Article</Button>
                  </Link>
            } else if (cat.slug === "insights") {
                return <Link href={"/insights" + item.uri} aria-label={item.title} rel="canonical">
                  <Button variant="ghost2" rightIcon={<IconArrowUpRight size="12px"/>}> Read Article</Button>
                  </Link>
            }
          } 
          )}
          </Stack>
        </Stack>
      </Box>
  )
}

export default ImageWithOverlay